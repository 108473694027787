import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { gradientWhiteColor, bgDarkColor, bgWhiteColor } from "./style/colors";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from './hooks/themeHook';

// pages
import Home from './pages/home';
import Login from './pages/login';
import OauthCallback from './pages/oauthCallback';
// Pilots
import PilotTrainingExam from './pages/pilots/pilotTrainingExam';
import PilotSoftware from './pages/pilots/pilotSoftware';
import GuidelinesIFR from './pages/pilots/guidelinesIFR';
import GuidelinesVFR from './pages/pilots/guidelinesVFR';
// ATC
import AtcTrainingExam from './pages/atc/atcTrainingExam';
import AtcSoftware from './pages/atc/atcSoftware';
import AtcStations from './pages/atc/atcStations';
import AtcSSR from './pages/atc/atcSSR';
import AtcDocumentation from './pages/atc/atcDocumentation';
// About
import Staff from './pages/about/staff';
// Resources
import GCARequirements from './pages/resources/gcaRequirements';
import Charts from './pages/resources/charts';
// Misc
import NoPage from './pages/noPage';
// Components
import Navbar from './components/navbar/navbar';
import SideNavbar from './components/navbar/sideNavbar';
import Alert from './components/alert';
import QuickLinks from './components/quickLinks';
import ScrollToTop from './components/scrollToTop';
import Footer from './components/footer';
import Preloader from './components/preloader';

function App() {

  const [scroll, setScroll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {darkMode, setDarkMode} = useTheme();
  
  useEffect(() => {
    if(localStorage.getItem('darkMode')){
      let dark = localStorage.getItem('darkMode');
      if(dark === 'true'){
        setDarkMode(true);
      }else{
        setDarkMode(false);
      }
    }
  },[]);

  useEffect(()=> {
    function scrollToTop(){
      window.scrollTo({
          top:0,
      });
    }
    // Function to show scroll to top button
    const handleScroll = () => {
      if(window.scrollY > 200){
        setScroll(true);
      }else{
        setScroll(false);
      }
    }
    // set loading state to false on Load to remove the preloader
    const handleLoad = () => {
      setIsLoading(false);
    }

    scrollToTop();
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    window.addEventListener("load", handleLoad);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("load", handleLoad);
      window.removeEventListener("scroll", handleScroll);
    };
    
  }, []);

  return (
    <div className={`App w-screen sm:w-full relative min-h-screen transition duration-500 ease-in-out bg-gradient-to-t ${darkMode ? bgDarkColor : gradientWhiteColor}`}>
      {isLoading && <Preloader />}
      <Router>
        <Navbar />
        <SideNavbar />
        <QuickLinks />
        <Alert />
        <div className='w-full flex justify-center content-center pb-[450px] sm:pb-36'>
          <div className='w-full max-w-screen-xl'>
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/login" element={<Login />} /> */}
              {/* <Route path="/callback" element={<OauthCallback />} /> */}
              <Route path="/pilot-training-exams" element={<PilotTrainingExam />} />
              <Route path="/pilot-software" element={<PilotSoftware />} />
              <Route path="/guidelines-ifr" element={<GuidelinesIFR />} />
              <Route path="/guidelines-vfr" element={<GuidelinesVFR />} />
              <Route path="/atc-training-exams" element={<AtcTrainingExam />} />
              <Route path="/atc-software" element={<AtcSoftware />} />
              <Route path="/atc-stations" element={<AtcStations />} />
              <Route path="/ssr-codes" element={<AtcSSR />} />
              <Route path="/atc-documents" element={<AtcDocumentation />} />
              <Route path="/staff" element={<Staff />} />
              <Route path="/gca-requirements" element={<GCARequirements />} />
              <Route path="/charts" element={<Charts />} />
              <Route path="*" element={<NoPage />} />
            </Routes>
          </div>
        </div>
        <Footer />
        {scroll && <ScrollToTop />}
      </Router>
    </div>
  );
}

export default App;
