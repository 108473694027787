import { useEffect, useState } from "react";
import axios from "axios";
import { useTheme } from '../../hooks/themeHook';
import { bgDarkColor, bgWhiteColor, txtDarkColor, txtWhiteColor } from "../../style/colors";

const Topbar = () => {
    const {darkMode, setDarkMode} = useTheme();
    const [user, setUser] = useState(null);

    // const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]; 
    // function addZero(i) {
    //     if (i < 10) {i = "0" + i}
    //     return i;
    // }
    // const d = new Date();
    // let date = d.getUTCDate();
    // let month = months[d.getUTCMonth()];
    // let h = addZero(d.getUTCHours());
    // let m = addZero(d.getUTCMinutes());
    // let s = addZero(d.getUTCSeconds());
    // let time = h + ":" + m + ":" + s + " UTC";

    // const [dateNow, setdateNow] = useState(date);
    // const [monthNow, setmonthNow] = useState(month);
    // const [timeNow, setTimeNow] = useState(time);

    // useEffect(() => {

    //     const timeInterval = setInterval(() => {
    //         const d = new Date();
    //         let date = d.getUTCDate();
    //         let month = months[d.getUTCMonth()];
    //         let h = addZero(d.getUTCHours());
    //         let m = addZero(d.getUTCMinutes());
    //         let s = addZero(d.getUTCSeconds());
    //         let time = h + ":" + m + ":" + s + " UTC";
    //         setdateNow(date);
    //         setmonthNow(month);
    //         setTimeNow(time);
    //     }, 1000);
    //     return () => {
    //         clearInterval(timeInterval);
    //     };
    // });

    useEffect(() => {
        const fetchUserData = async () => {
          const token = localStorage.getItem("token");
          if (!token) return;
          try {
            const response = await axios.get("http://localhost:3001/user", {
              headers: { Authorization: `Bearer ${token}` },
            });
    
            console.log("User Info:", response.data);
            setUser(response.data);
          } catch (error) {
            console.error("Failed to fetch user:", error);
          }
        };
    
        fetchUserData();
    }, []);
    
    return(
        <div className={`${darkMode ? bgDarkColor : bgWhiteColor} absolute top-0 z-10 text-center min-h-6 w-full flex justify-center content-center transition duration-500 ease-in-out`}>
            <nav className="w-full max-w-screen-xl">
                <div className="w-full flex justify-end content-center pt-1 pe-2">
                    {/* <h3 className={`text-sm ${darkMode ? txtWhiteColor : txtDarkColor} font-bold px-1`}>{dateNow+" "}{monthNow+" "}{timeNow}</h3> */}
                    {user && <h3 className={`text-sm ${darkMode ? txtWhiteColor : txtDarkColor} font-bold px-1`}>{user.firstName +" "+ user.lastName+" "+user.id}</h3>}
                </div>
            </nav>
        </div>
    );
    // border rounded border-gray-100

}

export default Topbar;