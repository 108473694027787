import { useTheme } from '../hooks/themeHook';

const IvaoEgLogo = (props) => {
    const {darkMode, setDarkMode} = useTheme();
    const ComponentType = props.type;
    
    if(ComponentType === "footer"){
        return (
            <div className="eg-logo flex justify-center content-center w-48 me-2">
                <img src="ivao_logo_white.png" alt="ivao-eg-logo"></img>
            </div>
        );
    }else{
        return (
            <div className="eg-logo flex justify-center content-center w-48">
                <img src={`${darkMode ? "ivao_logo_white.png" : "ivao_logo_blue.png"}`} alt="ivao-eg-logo"></img>
            </div>
        );
    }
}

export default IvaoEgLogo;