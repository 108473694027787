import { useEffect } from 'react';
import { useNav } from '../../hooks/navHook';
import { useTheme } from '../../hooks/themeHook';
import { bgBlueColor, bgRedColor, txtDarkColor, txtGrayColor } from '../../style/colors';

const GCARequirements = () => {
    const {nav, setNav} = useNav();
    const {darkMode, setDarkMode} = useTheme();

    // set global variable for active page
    useEffect(() => {
        setNav('resources');
    }, []);

    return(
        <div className="flex justify-center content-center pb-10">
            <div className={`w-screen sm:w-full py-5 px-10 ${darkMode ? txtGrayColor : txtDarkColor}`}>
                <section className="w-full flex flex-col justify-center content-center">
                    <h1 className="text-center text-xl md:text-2xl font-bold pt-6 pb-2">Guest Controller Approval (GCA) Requirements</h1>
                    <div className="flex justify-center content-center mb-5"><div className={`w-96 h-0.5 ${darkMode ? bgRedColor : bgBlueColor}`}></div></div>
                    <h2 className="text-sm md:text-base">The Egyptian Division is participating in the GCA (Guest Controller Approval) program which is in accordance with R&R 5.1.7
                        Any user not being a member of the EG-Division, wants to control a FRA restricted position in EG-Division, has to request approval from EG-HQ.</h2>
                    <div className="text-xs sm:text-sm">
                        <h3 className="text-lg md:text-xl font-bold underline py-2">Requirements:</h3>
                        <ol className="list-decimal px-4">
                            <li className="pb-1">Send e-mail to EG-HQ@ivao.aero
                                <h3 className="py-1">The content of the email must contain the following</h3>
                                <ol className="list-disc px-4 pb-1">
                                    <li>Full member name</li>
                                    <li>IVAO ID</li>
                                    <li>Controller rating ( ADC or APC only )</li>
                                </ol>
                            </li>
                            <li className="pb-1">Member must be able to perform ATC duties in English language. Knowledge of the local language is not necessary.</li>
                            <li className="pb-1">Member must have sufficient knowledge of the LOA’s of the position requested and general procedures applied in EG-Division.</li>
                            <li className="pb-1">Theoretical and practical check will be performed by EG-Division Training Department.</li>
                            <li className="pb-1">Member behavior, must be in accordance with IVAO rules and Division spirits.</li>
                            <li className="pb-1">EG-Division HQ allows controlling only on certain FRA positions, member has to adhere to such restrictions
                                <ol className="list-disc px-4">
                                    <li>ADC – GND/TWR</li>
                                    <li>APC – GND/TWR/APP</li>
                                </ol>
                            </li>
                            <li className="pb-1">Approval and restrictions will be shown on member profile</li>
                            <li className="pb-1">Member awarded EG-DIV GCA approval must spend at least half of the online time as ATC in home division.
                                <h3>( Member Online history will be checked by EG-HQ )</h3>
                            </li>
                            <li>The GCA can be withdrawn at any time by EG-HQ if the requirements are not met any more without further notice.</li>
                        </ol>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default GCARequirements;