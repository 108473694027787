// Background colors
const bgDarkColor = "bg-[#121212]";
// const bgDarkGrayColor = "bg-gray-800";
const bgDarkGrayColor = "bg-[#101010]";
const bgGrayColor700 = "bg-gray-700";
const bgGrayColor = "bg-gray-500";
const bgGrayColor400 = "bg-gray-400";
const bgIVAOGrayColor = "bg-[#D7D7DC]";
const bgWhiteGrayColor = "bg-gray-200";
const bgWhiteColor = "bg-gray-100";
const bgBlueColor = "bg-[#0D2C99]";
const bgLightBlueColor = "bg-[#3C55AC]";
const bgRedColor = "bg-red-700";
const bgYellowColor = "bg-[#F9CC2C]";

// Text colors
const txtDarkColor = "text-gray-900";
const txtWhiteColor = "text-gray-200";
const txtGrayColor = "text-gray-300";
const txtDarkGrayColor = "text-gray-400";
const txtBlueColor = "text-[#0D2C99]";
const txtLightBlueColor = "text-[#3C55AC]";
const txtRedColor = "text-red-700";
const txtYellowColor = "text-[#F9CC2C]";

// Border colors
const borderWhiteColor = "border-gray-300";
const borderGrayColor = "border-gray-600";
const borderDarkColor = "border-gray-800";

//------------------------------------------------------------

// Hover Background colors
const hoverBGDarkColor = "hover:bg-gray-900";
const hoverBGDarkGrayColor = "hover:bg-gray-800";
const hoverBGGrayColor700 = "hover:bg-gray-700";
const hoverBGGrayColor = "hover:bg-gray-500";
const hoverBGWhiteColor = "hover:bg-gray-200";
const hoverBGWhiteGrayColor = "hover:bg-gray-300";
const hoverBGBlueColor = "hover:bg-[#0D2C99]";
const hoverBGLightBlueColor = "hover:bg-[#3C55AC]";
const hoverBGRedColor = "hover:bg-red-700";
const hoverBGYellowColor = "hover:bg-[#F9CC2C]";

// Hover Text colors
const hoverTxtDarkColor = "hover:text-gray-900";
const hoverTxtWhiteColor = "hover:text-gray-200";
const hoverTxtGrayColor = "hover:text-gray-300";
const hoverTxtBlueColor = "hover:text-[#0D2C99]";
const hoverTxtLightBlueColor = "hover:text-[#3C55AC]";
const hoverTxtRedColor = "hover:text-red-700";
const hoverTxtYellowColor = "hover:text-[#F9CC2C]";

// Gradient colors
const gradientDarkColor = "from-gray-800 to-gray-900";
const gradientWhiteColor = "from-gray-200 to-gray-100";

export {bgDarkColor, bgDarkGrayColor, bgGrayColor700, bgGrayColor, bgGrayColor400, bgIVAOGrayColor, bgWhiteGrayColor, bgWhiteColor, bgBlueColor, bgLightBlueColor, bgRedColor, bgYellowColor,
    txtDarkColor, txtWhiteColor, txtGrayColor, txtDarkGrayColor, txtBlueColor, txtLightBlueColor, txtRedColor, txtYellowColor, borderWhiteColor, borderGrayColor, borderDarkColor,
    gradientDarkColor, gradientWhiteColor,
    hoverBGDarkColor, hoverBGDarkGrayColor, hoverBGGrayColor700, hoverBGGrayColor, hoverBGWhiteColor, hoverBGWhiteGrayColor, hoverBGBlueColor, hoverBGLightBlueColor, hoverBGRedColor, hoverBGYellowColor,
    hoverTxtDarkColor, hoverTxtWhiteColor, hoverTxtGrayColor, hoverTxtBlueColor, hoverTxtLightBlueColor, hoverTxtRedColor, hoverTxtYellowColor
}
