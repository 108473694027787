import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from './providers/themeProvider';
import { NavProvider } from './providers/navProvider';
import './index.css';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <NavProvider>
        <App />
      </NavProvider>
    </ThemeProvider>
  </React.StrictMode>
);