import { useEffect } from 'react';
import { useNav } from '../hooks/navHook';
import { useTheme } from '../hooks/themeHook';
import { txtDarkColor, txtGrayColor } from "../style/colors";

const NoPage = () => {
    const {nav, setNav} = useNav();
    const {darkMode, setDarkMode} = useTheme();

    // set global variable for active page
    useEffect(() => {
        setNav('noPage');
    }, []);
    
    return(
        <div className="w-full h-96 flex flex-col justify-center content-center">
            <section className="w-full flex flex-col justify-center content-center text-center">
                <h1 className={`text-4xl font-bold ${darkMode ? txtGrayColor : txtDarkColor}`}>Page not found</h1>
            </section>
        </div>
    );

}

export default NoPage;