import { useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from '../../hooks/themeHook';
import { Tooltip } from 'react-tooltip';
import { txtDarkColor, gradientWhiteColor, txtWhiteColor, bgDarkColor } from "../../style/colors";

const HomeSocialSection = () => {
    const {darkMode, setDarkMode} = useTheme();
    const [facebookIconActive, setFacebookIconActive] = useState(false);
    // const [groupIconActive, setGroupIconActive] = useState(false);
    const [discordIconActive, setDiscordIconActive] = useState(false);
    const [instagramIconActive, setInstagramIconActive] = useState(false);
    const [youtubeIconActive, setYoutubeIconActive] = useState(false);
    const [emailIconActive, setEmailIconActive] = useState(false);

    return(
        <section className={`w-full flex flex-col justify-center content-center text-center p-10 bg-gradient-to-t ${darkMode ? bgDarkColor : gradientWhiteColor}`}>
            <h1 className={`text-2xl sm:text-3xl font-bold uppercase p-5 ${darkMode ? txtWhiteColor : txtDarkColor}`}>Connect with US</h1>
            <div className="flex flex-col justify-center content-center">
                <div className="min-h-20 flex justify-center content-center space-x-12 sm:space-x-24 md:space-x-32 lg:space-x-40">
                    <Link onMouseEnter={() => {setFacebookIconActive(true)}} onMouseLeave={() => {setFacebookIconActive(false)}} className="facebookPage-anchor-element h-fit pt-4" to="https://www.facebook.com/ivaoegpage" target="_blank">
                        <>
                            <img className="w-11" src={`${facebookIconActive ? "icons-social/icon-facebook-active.svg" : (darkMode ? "icons-social/icon-facebook-white.svg" : "icons-social/icon-facebook.svg")}`} alt="Facebook-page"></img>
                            <Tooltip style={{ backgroundColor: "rgb(31 41 55)", color: "rgb(243 244 246)", padding: "6px 10px" }} anchorSelect=".facebookPage-anchor-element" place="bottom">
                                Facebook
                            </Tooltip>
                        </>
                    </Link>
                    {/* <Link onMouseEnter={() => {setGroupIconActive(true)}} onMouseLeave={() => {setGroupIconActive(false)}} className="facebookGroup-anchor-element h-fit pt-4" to="https://www.facebook.com/groups/IVAO.EG/" target="_blank">
                        <>
                            <img className="w-12" src={`${groupIconActive ? "icons-social/icon-group-active.svg" : (darkMode ? "icons-social/icon-group-white.svg" : "icons-social/icon-group.svg")}`} alt="Facebook-group"></img>
                            <Tooltip style={{ backgroundColor: "rgb(31 41 55)", color: "rgb(243 244 246)", padding: "6px 10px" }} anchorSelect=".facebookGroup-anchor-element" place="bottom">
                                Facebook group
                            </Tooltip>
                        </>
                    </Link> */}
                    <Link onMouseEnter={() => {setDiscordIconActive(true)}} onMouseLeave={() => {setDiscordIconActive(false)}} className="discord-anchor-element h-fit pt-4" to="https://discord.gg/cr5qB7pUFP" target="_blank">
                        <> 
                            <img className="w-14" src={`${discordIconActive ? "icons-social/icon-discord-active.svg" : (darkMode ? "icons-social/icon-discord-white.svg" : "icons-social/icon-discord.svg")}`} alt="Discord"></img>
                            <Tooltip style={{ backgroundColor: "rgb(31 41 55)", color: "rgb(243 244 246)", padding: "6px 10px" }} anchorSelect=".discord-anchor-element" place="bottom">
                                 Discord
                            </Tooltip>
                        </>
                    </Link>
                    <Link onMouseEnter={() => {setInstagramIconActive(true)}} onMouseLeave={() => {setInstagramIconActive(false)}} className="instagram-anchor-element h-fit pt-4" to="https://www.instagram.com/ivao.eg/" target="_blank">
                        <> 
                            <img className="w-10" src={`${instagramIconActive ? "icons-social/icon-instagram-active.svg" : (darkMode ? "icons-social/icon-instagram-white.svg" : "icons-social/icon-instagram.svg")}`} alt="Instagram"></img>
                            <Tooltip style={{ backgroundColor: "rgb(31 41 55)", color: "rgb(243 244 246)", padding: "6px 10px" }} anchorSelect=".instagram-anchor-element" place="bottom">
                                 Instagram
                            </Tooltip>
                        </>
                    </Link>
                    <Link onMouseEnter={() => {setYoutubeIconActive(true)}} onMouseLeave={() => {setYoutubeIconActive(false)}} className="youtube-anchor-element h-fit pt-4" to="https://www.youtube.com/@IVAOEGYPT" target="_blank">
                        <>
                            <img className="w-12" src={`${youtubeIconActive ? "icons-social/icon-youtube-active.svg" : (darkMode ? "icons-social/icon-youtube-white.svg" : "icons-social/icon-youtube.svg")}`} alt="Youtube"></img>
                            <Tooltip style={{ backgroundColor: "rgb(31 41 55)", color: "rgb(243 244 246)", padding: "6px 10px" }} anchorSelect=".youtube-anchor-element" place="bottom">
                                    Youtube
                            </Tooltip>
                        </>
                    </Link>
                    <Link onMouseEnter={() => {setEmailIconActive(true)}} onMouseLeave={() => {setEmailIconActive(false)}} className="email-anchor-element h-fit pt-4" to="mailto:eg-staff@ivao.aero" target="_blank">
                        <>
                            <img className="w-10" src={`${emailIconActive ? "icons-social/icon-envelope-active.svg" : (darkMode ? "icons-social/icon-envelope-white.svg" : "icons-social/icon-envelope.svg")}`} alt="Email"></img>
                            <Tooltip style={{ backgroundColor: "rgb(31 41 55)", color: "rgb(243 244 246)", padding: "6px 10px" }} anchorSelect=".email-anchor-element" place="bottom">
                                    Email
                            </Tooltip>
                        </>
                    </Link>
                </div>
            </div>
        </section>
    );

}

export default HomeSocialSection;