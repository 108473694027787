import { useTheme } from '../hooks/themeHook';
import { bgDarkColor, bgWhiteColor} from '../style/colors';

const Preloader = () => {
    const {darkMode, setDarkMode} = useTheme();

    return(
        <div className={`absolute z-50 w-full min-h-screen max-h-full ${darkMode ? bgDarkColor : bgWhiteColor}`}>
            <div className='min-h-screen flex flex-col justify-center items-center'>
                { darkMode ? <img src='/EG-white.svg' alt='ivao-eg-logo' className='w-36 animate-pulse transition duration-10000 ease-in-out'></img> : <img src='/EG.svg' alt='ivao-eg-logo' className='w-36 animate-pulse transition duration-10000 ease-in-out'></img>}
                {/* <FontAwesomeIcon className={`animate-spin text-4xl transition duration-10000 ease-in-out ${darkMode ? txtRedColor : txtBlueColor}`} icon={faCircleNotch} /> */}
            </div>
        </div>
    );
}

export default Preloader;