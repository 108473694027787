// Home carousel data
const mainSliders = [
    {id:1, img:"sliders/ivao.jpg", alt:"ivao"},
    {id:2, img:"sliders/career.jpg", alt:"ivao-pilots-career"},
    {id:3, img:"sliders/atco.jpg", alt:"ivao-atc-career"},
    {id:4, img:"sliders/community.jpg", alt:"ivao-community"}
];

// Training Test data
const trainings = [
    {id:1, img:"images/home/AT.jpg", title: "ATC Training", des:"HECA (Cairo Tower) at Friday, 2nd February 2024 20:00z", alt:"Training"},
    {id:2, img:"images/home/AT.jpg", title: "ATC Training", des:"HEAT (Assiut Tower) at Saturday, 3rd February 2024 20:00z", alt:"Training"},
    {id:3, img:"images/home/PT.jpg", title: "PILOT Training", des:"HEAT (Assiut Tower) at Sunday, 4th February 2024 20:00z", alt:"Training"},
    {id:4, img:"images/home/AT.jpg", title: "ATC Training", des:"HECA (Cairo Tower) at Monday, 5th February 2024 20:00z", alt:"Training"},
];

// Tours Test data
const tours = [
    {id:1, link:"https://tours.th.ivao.aero/index.php?div=EG&tourid=2777", img:"https://eg.ivao.aero/download/tours25/IFR25.png", title: "Egyptair Domestic IFR Tour", year: "2025", description: "IVAO Egypt Division is pleased to introduce Egyptair Domestic IFR Tour 2025, a real flight operation of Egyptair.", alt:"Egyptair Domestic IFR Tour"},
    {id:2, link:"https://tours.th.ivao.aero/index.php?div=EG&tourid=2779", img:"https://eg.ivao.aero/download/tours25/RBG.png", title: "Air Arabia Egypt Tour", year: "2025", description: "Air Arabia Egypt is a low-cost airline based in Egypt. The airline is a subsidiary of Air Arabia and based at Alexandria's Borg El Arab Airport.", alt:"Air Arabia Egypt Tour"},
    {id:3, link:"https://tours.th.ivao.aero/index.php?div=EG&tourid=2772", img:"https://eg.ivao.aero/download/tours25/MSC.png", title: "Air Cairo Tour", year: "2025", description: "IVAO Egypt Division is pleased to introduce Air Cairo Tour, a real flight operation of Air Cairo Airline.", alt:"Air Cairo Tour"},
    {id:4, link:"https://tours.th.ivao.aero/index.php?div=EG&tourid=2773", img:"https://eg.ivao.aero/download/tours25/NIA.png", title: "Nile Air Tour", year: "2025", description: "IVAO Egypt Division is pleased to introduce Nile Air Tour, a real flight operation of Nile Air Airline.", alt:"Nile Air Tour"},
    {id:5, link:"https://tours.th.ivao.aero/index.php?div=EG&tourid=2778", img:"https://eg.ivao.aero/download/tours25/LONGHAUL.png", title: "Long Haul Tour", year: "2025", description: "IVAO Egypt Division is pleased to introduce Long Haul Tour 2025.", alt:"Long Haul Tour"},
    {id:6, link:"https://tours.th.ivao.aero/index.php?div=EG&tourid=2775", img:"https://eg.ivao.aero/download/tours25/VFR.png", title: "Discover The Nile", year: "2025", description: "IVAO Egypt Division is pleased to introduce Discover The Nile Tour.", alt:"Discover The Nile"},
    {id:7, link:"https://tours.th.ivao.aero/index.php?div=EG&tourid=2774", img:"https://eg.ivao.aero/download/tours25/TURBOPROP.png", title: "Turboprop Tour", year: "2025", description: "IVAO Egypt Division is pleased to introduce Turboprop Tour.", alt:"Turboprop Tour"},
    // {id:8, link:"https://tours.th.ivao.aero/index.php?div=EG&tourid=2789", img:"https://eg.ivao.aero/download/tours25/cargoII.png", title: "Cargo Tour", year: "2025", description: "Cairo International Airport (HECA) is a key cargo hub connecting Africa, Europe, and Asia. It handles agriculture, textiles, pharmaceuticals, and high-value goods, with major airlines like EgyptAir Cargo, Saudia Cargo, Qatar Airways Cargo, Emirates SkyCargo, Turkish Cargo, DHL, FedEx, and UPS operating from its advanced facilities.", alt:"Cargo Tour"}
];

// EG Event Test data
const egEvents = [
    {id:1, link: "https://tours.th.ivao.aero/index.php?div=EG&point_tourid=665", img:"images/events/onlineday25.jpg", title:"18:00z - Egypt Online Day", info:"Every Saturday IVAO Egypt will hold an Online Day Event, which will take place from 18z-21z.. The event will consist of two or more aerodromes or TMAs offering FULL ATC for the duration of the event.. Each Online Day will be in different locations, the locations will be chosen by members through voting through our EG Discord channel or through our EG Facebook page, the locations that receive the most votes will be those that will participate in the event.. Voting will start on Monday, and will end on the Thursday preceding the Saturday of the event.. If you have any questions, contact us via email: eg-ec@ivao.aero", alt:"IVAO Egypt Event"},
    // {id:2, link: "https://tours.th.ivao.aero/index.php?div=EG&tourid=2211", img:"images/events/onlineday24.jpg", title:"18:00z - Egypt Online Day", info:"Every Saturday IVAO Egypt will hold an Online Day Event, which will take place from 18z-21z.. The event will consist of two or more aerodromes or TMAs offering FULL ATC for the duration of the event.. Each Online Day will be in different locations, the locations will be chosen by members through voting through our EG Discord channel or through our EG Facebook page, the locations that receive the most votes will be those that will participate in the event.. Voting will start on Monday, and will end on the Thursday preceding the Saturday of the event.. If you have any questions, contact us via email: eg-ec@ivao.aero", alt:"Event"},
];

// Events Test data
const ivaoEvents = [
    {id:1, img:"images/events/AT_Online_Day_Winterzeit.jpg", title: "16:00 - [UA] Divisional Online Day", info:"IVAO Ukraine is pleased to announce Online Evenings in Ukraine! We are waiting for all of you every Tuesday from 1600z in Ukrainian airspace! For charts and sceneries refer to our website!", link: "#", alt:"Event"},
    {id:2, img:"images/events/AT_Online_Day_Winterzeit.jpg", title: "18:00 - [TR] Türkiye Online Day", info:"Join us every week on Tuesday to fill the skies and sectors over Türkiye!", link: "#", alt:"Event"},
    {id:3, img:"images/events/AT_Online_Day_Winterzeit.jpg", title: "18:00 - [DE] German Onlineday", info:"Every Tuesday after 7pm Slovenia local time, an online evening of the Slovenian Division takes place on the IVAO network. Air traffic control will be provided as much as possible.", link: "#", alt:"Event"},
    {id:4, img:"images/events/AT_Online_Day_Winterzeit.jpg", title: "16:00 - [AT] Online Day", info:"We are excited to invite you to our weekly online day, which will now take place every Tuesday! Come and enjoy our beatiful landscape and airports!", link: "#", alt:"Event"},
    {id:5, img:"images/events/AT_Online_Day_Winterzeit.jpg", title: "16:00 - [UA] Divisional Online Day", info:"IVAO Ukraine is pleased to announce Online Evenings in Ukraine! We are waiting for all of you every Tuesday from 1600z in Ukrainian airspace airspace airspace airspace! For charts and sceneries refer to our website!", link: "#", alt:"Event"},
    {id:6, img:"images/events/AT_Online_Day_Winterzeit.jpg", title: "16:00 - [UA] Divisional Online Day", info:"IVAO Ukraine is pleased to announce Online Evenings in Ukraine! We are waiting for all of you every Tuesday from 1600z in Ukrainian airspace airspace airspace airspace! For charts and sceneries refer to our website!", link: "#", alt:"Event"},
];

export {mainSliders, trainings, tours, egEvents, ivaoEvents};