import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { useNav } from '../../hooks/navHook';
import { useTheme } from '../../hooks/themeHook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { bgGrayColor700, bgRedColor, bgBlueColor, borderDarkColor, borderGrayColor, txtDarkColor, txtGrayColor, txtBlueColor } from "../../style/colors";

const Charts = () => {
    const {nav, setNav} = useNav();
    const {darkMode, setDarkMode} = useTheme();

    // set global variable for active page
    useEffect(() => {
        setNav('resources');
    }, []);

    return(
        <div className="flex justify-center content-center pb-10">
            <div className={`w-screen sm:w-full py-5 px-10 ${darkMode ? txtGrayColor : txtDarkColor}`}>
                <section className="w-full flex justify-center content-center mb-5">
                    <div className="sm:w-10/12 md:w-8/12 lg:w-6/12 flex flex-col justify-center content-center">
                        <h1 className="text-center text-xl md:text-2xl font-bold pt-6 pb-2">Charts</h1>
                        <div className="flex justify-center content-center mb-5"><div className={`w-16 h-0.5 ${darkMode ? bgRedColor : bgBlueColor}`}></div></div>
                        <div className={`flex flex-col justify-center content-center text-sm md:text-base border rounded shadow-md shadow-gray-600/40 mb-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>
                            <table className="table">
                                <thead>
                                    <tr className={`text-base font-bold ${txtGrayColor} ${bgGrayColor700}`}>
                                        <th className={`border-e ${borderGrayColor}`}>Name</th>
                                        <th className={`w-36 border-e ${borderGrayColor}`}>En-Route</th>
                                        <th className="w-28">Download</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center font-bold">
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>En-Route</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Low</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/En-Route-Low.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className="h-10">
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>En-Route</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>High</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/En-Route-High.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={`flex flex-col justify-center content-center text-sm md:text-base xl:text-lg border rounded shadow-md shadow-gray-600/40 mb-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>
                            <table className="table">
                                <thead>
                                    <tr className={`text-base font-bold ${txtGrayColor} ${bgGrayColor700}`}>
                                        <th className={`border-e ${borderGrayColor}`}>Name</th>
                                        <th className="w-28">Download</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center font-bold">
                                    <tr className={`h-10 ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Egypt Airports TORA</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/Egypt_Airports_TORA_data.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={`flex flex-col justify-center content-center text-sm md:text-base xl:text-lg border rounded shadow-md shadow-gray-600/40 ${darkMode ? borderGrayColor : borderDarkColor}`}>
                            <table className="table">
                                <thead>
                                    <tr className={`text-base font-bold ${txtGrayColor} ${bgGrayColor700}`}>
                                        <th className={`border-e px-1 ${borderGrayColor}`}>Name</th>
                                        <th className={`w-36 border-e ${borderGrayColor}`}>ICAO</th>
                                        <th className="w-28">Download</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center font-bold">
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Cairo</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECA</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HECA.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Hurghada</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEGN</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HEGN.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Sharm El Sheikh</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HESH</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HESH.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Borg El Arab</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEBA</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HEBA.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Marsa Alam</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEMA</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HEMA.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Aswan</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HESN</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HESN.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Assiut</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEAT</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HEAT.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Sphinx</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HESX</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HESX.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Capital</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECP</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HECP.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>October</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEOC</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HEOC.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>El Arish</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEAR</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HEAR.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Suhag</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HESG</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HESG.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Luxor</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HELX</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HELX.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Abu Simbel</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEBL</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HEBL.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>AL Alamein</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEAL</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HEAL.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Marsa Matruh</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEMM</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HEMM.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Port Said</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEPS</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HEPS.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>St Catherine</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HESC</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HESC.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>EL Tor</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HETR</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HETR.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Taba</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HETB</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HETB.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Dakhla</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEDK</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HEDK.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>EL Kharga</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEKG</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HEKG.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Almaza AB</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEAZ</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HEAZ.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                    <tr className="h-10">
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Bernice</td>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEBR</td>
                                        <td><Link to="https://eg.ivao.aero/download/charts/HEBR.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtGrayColor : txtBlueColor} hover:text-green-600 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Charts;