import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleChevronRight, faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useTheme } from '../hooks/themeHook';
import { Link } from 'react-router-dom';
import { txtBlueColor, txtLightBlueColor, txtDarkColor, txtDarkGrayColor, txtGrayColor, txtWhiteColor } from '../style/colors';

const Carousel = (props) => {
    const sliders = props.data;
    const {darkMode, setDarkMode} = useTheme();
    const [currentIndex, setCurrentIndex] = useState(1);
    const [carouselHovered, setcarouselHovered] = useState(false);

    useEffect(() => {
        // Autoplay Carousel
        const carouselInterval = setInterval(() => {
            if(!carouselHovered){
                goToNextSlide();
            }
        }, 5000);
        return () => {
            clearInterval(carouselInterval);
        };
    }, [currentIndex, carouselHovered]);

    // Carousel Fade effect Function
    function CarouselEffect(x){
        const carouselImg = document.querySelectorAll(".carousel-img")[x];
        if(darkMode){
            carouselImg.style.opacity = "0.75";
        }else{
            carouselImg.style.transition = "opacity 1s ease-in-out";
        }

        const carouselAnimation = setTimeout(() => {
            carouselImg.style.transition = "opacity 3s ease-in-out";
            if(darkMode){
                carouselImg.style.opacity = "1";
            }else{
                carouselImg.style.opacity = "1";
            }
        }, 1000);
        return () => {
            clearInterval(carouselAnimation);
        };
    }

    // Carousel Arrows effect Function
    function CarouselArrowEffect(){
        const carouselArrowR = document.querySelector(".carousel-arrow-r");
        const carouselArrowL = document.querySelector(".carousel-arrow-l");

        if(!carouselHovered){
            carouselArrowR.style.opacity = "1";
            carouselArrowL.style.opacity = "1";
            carouselArrowR.style.transition = "opacity 0.5s ease-in-out";
            carouselArrowL.style.transition = "opacity 0.5s ease-in-out";
        }else{
            carouselArrowR.style.opacity = "0";
            carouselArrowL.style.opacity = "0";
        }
    }

    // Next Carousel Image Function
    const goToNextSlide = () => {
        if(sliders.length === currentIndex){
            setCurrentIndex(1);
        }else{
            setCurrentIndex(currentIndex + 1);
        }
        CarouselEffect(0);
        CarouselEffect(1);
    };
    
    // Prev Carousel Image Function
    const goToPrevSlide = () => {
        if(currentIndex === 1){
            setCurrentIndex(sliders.length);
        }else{
            setCurrentIndex(currentIndex - 1);
        }
        CarouselEffect(0);
        CarouselEffect(1);
    };

    // Carousel dots component
    const Dots = (props) => {
        return <li id={props.id} onClick={() => {setCurrentIndex(props.id)}}><FontAwesomeIcon icon={faCircle} className={`${props.id === currentIndex ? 'w-2 mx-1 px-1 py-1 cursor-pointer '+(darkMode ? txtWhiteColor : txtBlueColor) : 'w-2 mx-1 px-1 py-1 cursor-pointer '+txtDarkGrayColor}`} /></li>
    }

    return(
        <div onMouseEnter={() => {setcarouselHovered(true); CarouselArrowEffect()}} onMouseLeave={() => {setcarouselHovered(false); CarouselArrowEffect()}} className='flex flex-col justify-center content-center'>
            <div className='sm:min-h-48 md:min-h-80 lg:min-h-96 relative flex flex-col justify-center content-center'>
                {/* Main carousel */}
                {!sliders[0].link &&
                    <>
                        <div onClick={goToNextSlide} className='carousel-arrow-r hidden sm:block absolute z-30 right-0 me-5 opacity-0'>
                            <FontAwesomeIcon icon={faCircleChevronRight} className={`${txtWhiteColor} hover:text-gray-100 hover:scale-105 text-4xl cursor-pointer`} />
                        </div>
                        <div onClick={goToPrevSlide} className='carousel-arrow-l hidden sm:block absolute z-30 left-0 ms-5 opacity-0'>
                            <FontAwesomeIcon icon={faCircleChevronLeft} className={`${txtWhiteColor} hover:text-gray-100 hover:scale-105 text-4xl cursor-pointer`} />
                        </div>
                    </>
                }
                {/* Tours carousel */}
                {sliders[0].link &&
                    <div className='h-full'>
                        <div onClick={goToPrevSlide} className='carousel-arrow-l absolute left-0 h-full z-30 opacity-60 xl:opacity-100 hover:opacity-100 cursor-pointer'>
                            <div className='flex justify-start items-center h-full w-36 md:w-24 ms-8 xl:ms-1'>
                                <FontAwesomeIcon icon={faCircleChevronLeft} className={`motion-safe:animate-pulse hover:animate-none ${txtWhiteColor} xl:text-gray-500 ${"hover:"+txtGrayColor} ${darkMode ? "xl:hover:text-gray-400" : "xl:hover:text-gray-700" } hover:scale-105 text-4xl cursor-pointer`} />
                            </div>
                        </div>
                        <div onClick={goToNextSlide} className='carousel-arrow-r absolute right-0 h-full z-30 opacity-60 xl:opacity-100 hover:opacity-100 cursor-pointer'>
                            <div className='flex justify-end items-center h-full w-36 md:w-24 me-8 xl:me-1'>
                                <FontAwesomeIcon icon={faCircleChevronRight} className={`motion-safe:animate-pulse hover:animate-none ${txtWhiteColor} xl:text-gray-500 ${"hover:"+txtGrayColor} ${darkMode ? "xl:hover:text-gray-400" : "xl:hover:text-gray-700" } hover:scale-105 text-4xl cursor-pointer`} />
                            </div>
                        </div>
                    </div>
                }
                {/* Main carousel Images*/}
                {!sliders[0].link && <img className={`carousel-img w-full ${darkMode ? 'opacity-75' : ''}`} src={sliders[currentIndex-1].img} alt={sliders[currentIndex-1].alt}></img>}
                {/* Tours carousel Images*/}
                {sliders[0].link &&
                <Link className='flex justify-center content-start' to={sliders[currentIndex-1].link} target="_blank">
                    <img className={`carousel-img w-full max-w-[800px] ${darkMode ? 'opacity-75' : ''}`} src={sliders[currentIndex-1].img} alt={sliders[currentIndex-1].alt}></img>
                </Link>}
            </div>
            <div className="dots hidden sm:block">
                <ul className='flex justify-center content-center'>
                    {sliders && sliders.map((data) => {
                        return (
                            <Dots id={data.id} key={data.id} />
                        );
                    })}
                </ul>
            </div>
            {/* Tours carousel Text*/}
            {sliders[0].title &&
            <div className={`tour-text text-xl md:text-2xl text-center font-bold pt-2 pb-2`}>
                <Link className={`${darkMode ? txtLightBlueColor : txtBlueColor} uppercase pb-2 hover:animate-pulse hover:underline`} to={sliders[currentIndex-1].link} target="_blank">{sliders[currentIndex-1].title+" "+sliders[currentIndex-1].year}</Link>
                {sliders[0].description && <p className={`${darkMode ? txtGrayColor : txtDarkColor} text-start text-base px-4`}>{sliders[currentIndex-1].description}</p>}
            </div>}
        </div>
    );

}

export default Carousel;