import { useEffect } from 'react';
import { useNav } from '../../hooks/navHook';
import { useTheme } from '../../hooks/themeHook';
import { txtDarkColor, txtLightBlueColor, bgIVAOGrayColor, bgGrayColor400, bgRedColor, bgBlueColor, txtGrayColor } from '../../style/colors';

const GuidelinesIFR = () => {
    const {nav, setNav} = useNav();
    const {darkMode, setDarkMode} = useTheme();

    // set global variable for active page
    useEffect(() => {
        setNav('pilots');
    }, []);

    return(
        <div className="flex justify-center content-center pb-10">
            <div className={`w-screen sm:w-full py-5 px-10 ${darkMode ? txtGrayColor : txtDarkColor}`}>
                <section className="w-full flex flex-col justify-center content-center">
                    <h1 className="text-center text-2xl font-bold pt-6 pb-2">Guidelines IFR</h1>
                    <div className="flex justify-center content-center mb-5"><div className={`w-32 h-0.5 ${darkMode ? bgRedColor : bgBlueColor}`}></div></div>
                    <h2 className="text-center text-xl font-bold pb-6">Pilot Guidelines – Instrument Flight Rules (IFR)</h2>
                    <ol className="list-disc mb-6 ps-4 text-sm sm:text-base">
                        <li><h3>In real life you should file a flight plan before starting an IFR flight</h3></li>
                        <li><h3>There is no altitude limit to fly IFR</h3></li>
                        <li><h3>IFR means that you fly and navigate to your destination by means of Navigational aids such as VOR beacons, NDB Beacons and Intersections.</h3></li>
                        <li><h3>If your flight commences outside controlled airspace (see airway charts) you do not need clearance to operate, however you must request an information service from an ATC unit and remain outside controlled airspace until cleared to enter.</h3></li>
                    </ol>
                </section>
                <section className={`w-full flex flex-col justify-center content-center text-xs sm:text-sm ${txtDarkColor}`}>
                    <table className="mb-4 shadow-md shadow-gray-600/40">
                        <tbody>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`w-28 text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Cairo Delivery good evening , MSRxxx , AirBus A320 , Gate F 3 with information Bravo, request IFR Clearance to Hurghada .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HECA_DEL :</td>
                                <td className="ps-3">MSR xxx , good evening, cleared to Hurghada as filed, after departure Climb to altitude 3500 ft , runway heading , Squawk 4405 .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Cleared to Hurghada as filed, after departure Climb to altitude 3500 ft , runway heading , Squawk 4405 , MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HECA_DEL :</td>
                                <td className="ps-3">MSR xxx , correct , contact Cairo Ground 121.900 .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">contact Cairo Ground 121.900 .</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="mb-4 shadow-md shadow-gray-600/40">
                        <tbody>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`w-28 text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Cairo Ground MSR xxx , AirBus A320 , Gate F 3 , ready to start-up and pushback .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HECA_GND :</td>
                                <td className="ps-3">MSR xxx , start-up and pushback approved , rwy in use 05C .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Start and push approved , 05C for departure, MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Ground, MSR xxx request taxi .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HECA_GND :</td>
                                <td className="ps-3">MSR xxx taxi to holding point rwy 05C via B , S , QNH 1012 .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">taxi to holding point rwy 05C via B , S , QNH 1012 .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Ground, MSR xxx at holding point rwy 05C ready for departure .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HECA_GND :</td>
                                <td className="ps-3">MSR xxx , Cairo Tower 118.100 .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Cairo Tower 118.100 , MSR xxx .</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="mb-4 shadow-md shadow-gray-600/40">
                        <tbody>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`w-28 text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Cairo Tower , MSR xxx holding point rwy 05C ready for departure .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HECA_TWR :</td>
                                <td className="ps-3">MSR xxx , line up and wait runway 05C .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">line up and wait runway 05C , MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HECA_TWR :</td>
                                <td className="ps-3">MSR xxx , Cleard for take off rwy 05C, surface wind 080 degrees, 12 knots .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Runway 05C, cleared take-off, MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">airborne , MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HECA_TWR :</td>
                                <td className="ps-3">MSR xxx, contact Cairo departure 119.050 .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Cairo departure 119.050 , MSR xxx .</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="mb-4 shadow-md shadow-gray-600/40">
                        <tbody>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`w-28 text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Cairo departure, MSR xxx airborne 05C, passing 1500ft for 3500ft , runway heading .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HECA_DEP :</td>
                                <td className="ps-3">MSR xxx radar contact , alt 1700 ft , climb FL230 , Fly heading 180 to intercept outbound radial 161 from CVO vor , report establish .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">climb FL 230 , heading 180 to intercept outbound radial 161 from CVO vor , will report establish MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">establish on radial 161 from CVO VOR , MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HECA_DEP :</td>
                                <td className="ps-3">MSR xxx , contact Cairo Control 125.300 .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">contact Cairo Control 125.300 , MSR xxx .</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="mb-4 shadow-md shadow-gray-600/40">
                        <tbody>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`w-28 text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Cairo Control, MSR xxx passing FL170 for FL230 , inbound to SEMRU .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HECC_CTR :</td>
                                <td className="ps-3">MSR xxx , radar contact FL170 , climb and maintain final FL 230 , proceed as filed .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Climb FL230, proceed as filed , MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HECC_CTR :</td>
                                <td className="ps-3">MSR xxx , Report when ready for descent .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Roger, will report when ready for descent , MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Cairo Control, MSR xxx ready for initial descent .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HECC_CTR :</td>
                                <td className="ps-3">MSR xxx , initially descend and maintain FL150 .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Descent FL150 , MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HECC_CTR :</td>
                                <td className="ps-3">MSR xxx , Contact Hurghada approach 123.400 .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Hurghada approach 123.400 , MSR xxx .</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="mb-4 shadow-md shadow-gray-600/40">
                        <tbody>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`w-28 text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Hurghada approach , MSR xxx with you at FL150 inbound HGD .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HEGN_APP :</td>
                                <td className="ps-3">MSR xxx , radar contact, decsent Alt 7500 ft , qnh 1018 , expect radar vectors for ILS approach rwy 34R .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">decsent Alt 7500 ft , qnh 1018 , expect vectors for ILS approach rwy 34R , MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HEGN_APP :</td>
                                <td className="ps-3">MSR xxx , continue decsent alt 4000 ft .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">decsent alt 4000 ft , MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HEGN_APP :</td>
                                <td className="ps-3">MSR xxx ,right heading 140 , descent 2500 ft .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">right heading 140 , descent 2500 ft , MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HEGN_APP :</td>
                                <td className="ps-3">MSR xxx , Fly heading 260 .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">heading 260 , MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HEGN_APP:</td>
                                <td className="ps-3">turn Right heading 320 , cleared to intercept ILS rwy 34R , report localiser establish .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Right heading 320 , cleared to intercept ILS rwy 34R , will report establish .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Hurghada approach , MSR xxx established ILS rwy 34R .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HEGN_APP :</td>
                                <td className="ps-3">MSR xxx , continue approach 34R , contact Hurghada tower on 119.600 .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">continue approach , contact Hurghada tower on 119.600 , MSR xxx .</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="mb-4 shadow-md shadow-gray-600/40">
                        <tbody>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`w-28 text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Hurghada tower , MSR xxx established ILS 34R .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HEGN_TWR :</td>
                                <td className="ps-3">MSR xxx , wind 320 degrees at 7 knots, rwy 34R , cleared to land .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Cleared to land runway 34R , MSR xxx .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Tower, MSR xxx vacated rwy 34R via G .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HEGN_TWR :</td>
                                <td className="ps-3">MSR xxx ,contact Ground on 121.900 .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Hurghada ground on 121.900 , MSR xxx .</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="mb-4 shadow-md shadow-gray-600/40">
                        <tbody>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`w-28 text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">Hurghada ground , MSR xxx vacated runway 34R via G .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1 border-b border-gray-200`}>
                                <td className={`text-center font-bold ${bgGrayColor400}`}>HEGN_GND :</td>
                                <td className="ps-3">MSR xxx taxi to gate 18 via A and M .</td>
                            </tr>
                            <tr className={`h-12 ${bgIVAOGrayColor} p-1`}>
                                <td className={`text-center ${txtLightBlueColor} font-bold ${bgGrayColor400}`}>MSR xxx :</td>
                                <td className="ps-3">gate 18 via A and M , MSR xxx .</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </div>
        </div>
    );
}

export default GuidelinesIFR;