import { useState } from 'react';
import { useTheme } from '../hooks/themeHook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { txtDarkColor, txtWhiteColor, bgBlueColor, bgIVAOGrayColor } from "../style/colors";

const Alert = () => {
    const {darkMode, setDarkMode} = useTheme();
    const [alertStatus, setAlertStatus] = useState(true);

    return(
        <>
            {alertStatus && <div className={`w-full h-12 md:h-11 lg:h-8 relative flex flex-col justify-center items-center ${ darkMode ? bgIVAOGrayColor : bgBlueColor }`}>
                <div className='mx-7 py-1'><h2 className={`text-xs md:text-sm lg:text-base text-center font-bold ${ darkMode ? txtDarkColor : txtWhiteColor}`}>This website is for Simulation purposes only and none of the Information onboard is to be used for real world Navigation.</h2></div>
                <div onClick={() => setAlertStatus(false)} className='absolute h-full flex flex-col justify-center items-center right-0 cursor-pointer hover:scale-110 p-2 mx-1'><FontAwesomeIcon className={`text-xl ${darkMode ? txtDarkColor : txtWhiteColor}`} icon={faXmark} /></div>
            </div>}
        </>
    );
}
 
export default Alert;