import { useTheme } from '../hooks/themeHook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCaretUp } from '@fortawesome/free-solid-svg-icons'
import { txtBlueColor, txtWhiteColor, bgDarkGrayColor, bgWhiteGrayColor} from '../style/colors';

const ScrollToTop = () => {
    const {darkMode, setDarkMode} = useTheme();

    function scrollToTop(){
        window.scrollTo({
            top:0,
            behavior: 'smooth',
        });
    }

    // const hideButtonBackground = () => {
    //     document.querySelector('.scrollBtnBackground').style.visibility = "hidden";
    // }
    // const showButtonBackground = () => {
    //     document.querySelector('.scrollBtnBackground').style.visibility = "visible";
    // }

    return(
        <div onClick={scrollToTop} className={`z-40 fixed flex justify-center items-center bottom-0 right-0 rounded me-3 mb-0.25 cursor-pointer`}>
            <div className={`scrollBtnBackground h-7 w-8 absolute rounded opacity-80 blur-sm ${darkMode ? bgDarkGrayColor : bgWhiteGrayColor}`}></div>
            <div className={`z-40 text-4xl ${darkMode ? txtWhiteColor : txtBlueColor} hover:scale-105 transition duration-200 ease-in-out`}><FontAwesomeIcon icon={faSquareCaretUp} /></div>
        </div>
    );

}

export default ScrollToTop;